import { supabase } from "../../utils/supbase";

const GetLawyersChart = async () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  let { data, error } = await supabase
    .from("lawyers")
    .select("created_at")
    .gte("created_at", `${currentYear}-01-01 00:00:00.73894+00`);

  if (error) {
    console.error("Error fetching lawyers data:", error);
    return [];
  }

  const lawyersCounts = data.reduce((acc, element) => {
    const month = new Date(element.created_at).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    acc[month] = (acc[month] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(lawyersCounts).map(([month, count]) => ({
    month,
    count,
  }));
};

const GetIndividualsChart = async () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  let { data, error } = await supabase
    .from("individuals")
    .select("created_at")
    .gte("created_at", `${currentYear}-01-01 00:00:00.73894+00`);

  if (error) {
    console.error("Error fetching individuals data:", error);
    return [];
  }
  const individualsCounts = data.reduce((acc, element) => {
    const date = new Date(element.created_at).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(individualsCounts).map(([date, count]) => ({
    date,
    count,
  }));
};

const getLawyersCount = async () => {
  try {
    let { data, error } = await supabase
      .from("lawyers")
      .select("id", { count: "exact" })
      .single();

    if (error) {
      console.error("Error fetching lawyers count:", error.message);
      return 0; // Return 0 or handle the error appropriately
    }

    return data.count;
  } catch (error) {
    console.error("Error fetching lawyers count:", error.message);
    return 0; // Return 0 or handle the error appropriately
  }
};

export { GetLawyersChart, GetIndividualsChart, getLawyersCount };
