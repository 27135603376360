import Swal from "sweetalert2";
import { supabase } from "../../utils/supbase";

const deleteBasicData = async (tableName, getData, id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      await supabase
        .from(tableName)
        .delete()
        .eq("id", id)
        .then((data, error) => {
          if (data) {
            Swal.fire(
              "Done!",
              "Data has been deleted successfully.",
              "success"
            );
            getData();
          } else if (error) {
            Swal.fire(
              "Error!",
              "An unexpected error occurred, please try again later",
              "error"
            );
          }
        });
    }
  });
};

export default deleteBasicData;
