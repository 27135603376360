import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import { Phone, Email, DateRange } from "@mui/icons-material";
import editBasicData from "../../services/basicData/EditBasicData";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
}));

const StyledName = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));
const OrganizationDetails = ({ user, getUsers }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AssignmentIndIcon color="primary" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              phone_number: formData.get("phone_number"),
            };
            console.log(formJson);
            editBasicData(
              "organizations",
              {
                phone_number: formData.get("phone_number"),
              },
              getUsers,
              handleClose,
              user.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Organizition Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeader
                  disableTypography="alse"
                  avatar={
                    <StyledAvatar alt={user.full_name} src={user.image_url} />
                  }
                  title={<StyledName variant="h4">{user.full_name}</StyledName>}
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>
                        <Typography
                          variant="h5"
                          color="goldenrod"
                          fontWeight="bold"
                          style={{ marginBottom: "8px" }}
                        >
                          Contact Information
                        </Typography>
                      </Typography>
                      {user.email ? (
                        <Typography
                          variant="body2"
                          style={{ display: "flex", alignItems: "center" }}
                          gutterBottom
                        >
                          <Email fontSize="32px" style={{ margin: "4px" }} />{" "}
                          <Typography variant="body2">{user.email}</Typography>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {user.phone_number ? (
                        <Typography
                          variant="body2"
                          gutterBottom
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Phone fontSize="32px" style={{ margin: "4px" }} />{" "}
                          <Typography variant="body2">
                            {user.phone_number}
                          </Typography>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>
                        <Typography
                          variant="h5"
                          color="goldenrod"
                          fontWeight="bold"
                          style={{ marginBottom: "8px" }}
                        >
                          Personal Details
                        </Typography>
                      </Typography>
                      {user.company_register_issuance_date ? (
                        <Typography
                          variant="body2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <QueryBuilderIcon
                            fontSize="32px"
                            style={{ margin: "4px" }}
                          />
                          <Typography variant="body2">
                            {user.company_register_issuance_date}
                          </Typography>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {user.company_register ? (
                        <Typography
                          variant="body2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <VpnKeyIcon
                            fontSize="32px"
                            style={{ margin: "4px" }}
                          />
                          <Typography variant="body2">
                            {user.company_register}
                          </Typography>
                        </Typography>
                      ) : (
                        ""
                      )}

                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <DateRange fontSize="32px" style={{ margin: "4px" }} />
                        <Typography variant="body2">
                          {new Date(user.created_at).toLocaleDateString()}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardContent>
                  <Typography
                    variant="h5"
                    color="goldenrod"
                    fontWeight="bold"
                    style={{ marginBottom: "8px" }}
                  >
                    Edit Phone Number
                  </Typography>
                  <TextField
                    required
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    fullWidth
                    variant="standard"
                    margin="dense"
                    defaultValue={user?.phone_number}
                  />
                  <Button type="submit" variant="contained" color="success">
                    Submit
                  </Button>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrganizationDetails;
