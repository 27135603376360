import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import createBasicData from "../../services/basicData/CreateBasicData";

const NewWorkArea = ({ getWorkAreas }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Add New Work Area
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              name_ar: formData.get("name_ar"),
              name_en: formData.get("name_en"),
            };
            console.log(formJson);
            createBasicData("work_areas", formJson, getWorkAreas, handleClose);
          },
        }}
      >
        <DialogTitle fontWeight="bold">Add New Work Area</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="name_ar"
            name="name_ar"
            label="Name Arabic"
            fullWidth
            variant="standard"
            margin="dense"
          />
          <TextField
            required
            id="name_en"
            name="name_en"
            label="Name English"
            fullWidth
            variant="standard"
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewWorkArea;
