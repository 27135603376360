import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/services/authApi";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const submitLoginFormHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    dispatch(
      loginUser({
        userName: formData.get("userName"),
        password: formData.get("password"),
      })
    );
    if (user) {
      setTimeout(() => navigate("/supervisor"), 2000);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="my-20" dir="ltr">
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "3px 3px 20px -8px #000",
            borderRadius: 5,
            p: 3,
          }}
        >
          <img src="imgs/logo.png" alt="" width={"200px"} />
          <Typography
            component="h1"
            variant="h5"
            fontWeight="bold"
            color="goldenrod"
          >
            Adala App Dashboard
          </Typography>
          <Box
            component="form"
            onSubmit={submitLoginFormHandler}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              type="text"
              name="userName"
              autoComplete="userName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              type="password"
              name="password"
              autoComplete="password"
            />
            <Button
              type="submit"
              // disabled={!password || !userName}
              fullWidth
              variant="contained"
              color="inherit"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default LoginScreen;
