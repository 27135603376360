import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";
import UsersDetails from "../users/UserDetails.jsx";
import OrganizitionDetails from "../Organizations/OrganizitionDetails.jsx";
import LawyerDetails from "../lawyers/LawyerDetails";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import { Phone, Email, DateRange } from "@mui/icons-material";
import editBasicData from "../../services/basicData/EditBasicData";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
}));

const StyledName = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const StyledInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop:"10px"
}));

const OrderDetails = ({ order, getOrders, offers }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AssignmentIcon color="primary" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}       
      >
        <DialogTitle fontWeight="bold">Order Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardContent>
                  <Grid container >
                    <Typography variant="body2">
                      <StyledName variant="h4">Order #: {order.id}</StyledName>
                    </Typography>
                    
                    <Grid item xs={12} sm={12} style={{display:"flex"}} >
                      {order.services? (
                         <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                          <CircleIcon style={{ margin: "4px", fontSize:"8px"}} />{" "}
                          <Typography variant="body2">
                            {order.services?.name_ar}
                          </Typography>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {order.services? (
                        <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                          <CircleIcon fontSize="15px" style={{ margin: "4px", fontSize:"8px" }} />{" "}
                          <Typography variant="body2">
                            {order.services?.category}
                          </Typography>
                        </Typography>
                      ) : (
                        ""
                      )}
                      
                      <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        <DateRange fontSize="32px" style={{ margin: "4px" }} />
                        <Typography variant="body2">
                          {new Date(order.created_at).toLocaleDateString()}
                        </Typography>
                      </Typography>

                      <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        {order.organizations?
                          <OrganizitionDetails getUsers={()=>{}} user={order.organizations} />
                          :
                          <UsersDetails getUsers={()=>{}} user={order.individuals} />
                        }
                        {order.organizations? order.organizations?.full_name : order.individuals?.full_name }

                        </Typography>
                    </Grid>

                    <Typography variant="body2" >
                      <StyledInfo variant="body1">{order.overview}</StyledInfo>
                    </Typography>

                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledCard>
              
                <CardContent>
                  <Typography variant="h5">
                    Offers :  {offers.length}
                  </Typography>

                  <Typography variant="body1">
                    Status :  {order.orders_statuses?.name_ar}
                  </Typography>

                 {offers.filter(x=>x.order_id==order.id && x.offer_status_id==2).length>0 ? 
                 <Typography variant="body1">
                    Lawyer :  {<LawyerDetails getLawyers={()=>{}} lawyer={offers.filter(x=>x.order_id==order.id && x.offer_status_id==2)[0].lawyers} 
                    offers={offers.filter(x=>x.lawyer_id==offers.filter(x=>x.order_id==order.id && x.offer_status_id==2)[0].lawyer_id)} />}
                    {offers.filter(x=>x.order_id==order.id && x.offer_status_id==2)[0].lawyers.full_name}
                  </Typography>
                  :""
                  }
                   
                </CardContent>
              </StyledCard>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDetails;
