import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import editBasicData from "../../services/basicData/EditBasicData";

const EditAboutUs = ({ getAboutUs, aboutUs }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="info" onClick={handleClickOpen}>
        Edit Founder Information
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              founder_name_ar: formData.get("founder_name_ar"),
              founder_name_en: formData.get("founder_name_en"),
              founder_description_ar: formData.get("founder_description_ar"),
              founder_description_en: formData.get("founder_description_en"),
              content_ar: formData.get("content_ar"),
              content_en: formData.get("content_en"),
            };
            editBasicData(
              "about_us",
              formJson,
              getAboutUs,
              handleClose,
              aboutUs.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Edit Founder Data</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="founder_name_ar"
            name="founder_name_ar"
            label="Founder Name Arabic"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.founder_name_ar}
          />
          <TextField
            required
            id="founder_description_ar"
            name="founder_description_ar"
            label="Founder Description Arabic"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.founder_description_ar}
          />
          <TextField
            required
            id="founder_name_en"
            name="founder_name_en"
            label="Founder Name English"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.founder_name_en}
          />
          <TextField
            required
            id="founder_description_en"
            name="founder_description_en"
            label="Founder Description English"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.founder_description_en}
          />
          <TextField
            required
            id="content_ar"
            name="content_ar"
            label="Content Arabic"
            fullWidth
            multiline
            maxRows={8}
            dir="rtl"
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.content_ar}
          />
          <TextField
            required
            id="content_en"
            name="content_en"
            label="Content English"
            fullWidth
            multiline
            maxRows={8}
            variant="standard"
            margin="dense"
            defaultValue={aboutUs?.content_en}
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditAboutUs;
