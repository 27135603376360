import { Box } from "@mui/material";
import React from "react";
import { Vortex } from "react-loader-spinner";
const Spinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <Vortex
        visible={true}
        height="150"
        width="150"
        ariaLabel="vortex-loading"
        wrapperStyle={{}}
        wrapperClass="vortex-wrapper"
        colors={[
          "goldenrod",
          "gray",
          "goldenrod",
          "goldenrod",
          "gray",
          "goldenrod",
        ]}
      />
    </Box>
  );
};

export default Spinner;
