import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DeleteIcon from "@mui/icons-material/Delete";
import deleteBasicData from "../../services/basicData/deleteBasicData";
import NewBank from "./NewBank";
import EditBank from "./EditBank";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const BanksList = () => {
  const [banks, setBanks] = useState();

  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = async () => {
    const { data } = await supabase.from("banks").select("*");
    setBanks(data);
  };

  const deleteBank = async (id) => {
    deleteBasicData("banks", getBanks, id);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {banks ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Banks List
              </Typography>
              <NewBank getBanks={getBanks} />
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {banks.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={
                        <>
                          {/* <EditIcon color="primary" onClick /> */}
                          <EditBank getBanks={getBanks} bank={element} />
                          <IconButton onClick={() => deleteBank(element.id)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AccountBalanceIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={element.name_ar}
                        secondary={element.name_en}
                      />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default BanksList;
