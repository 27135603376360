import * as React from "react";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { supabase } from "../../utils/supbase";
import { Container, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import OrganizationDetails from "./OrganizitionDetails";

export default function OrganizitionsList() {
  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    getOrganizitions();
  }, []);

  const getOrganizitions = async () => {
    const { data } = await supabase
      .from("organizations")
      .select(
        `*, 
    cities(id, name_en, name_ar)
    `
      )
      .order("created_at", { ascending: false });
    console.log(data);
    setUsers(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredUsers = users?.filter(
    (user) =>
      user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {users ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Organizitions List
              </Typography>
            </Paper>
            <div>
              <div>
                <TextField
                  variant="outlined"
                  label="Search Users"
                  fullWidth
                  size="large"
                  margin="normal"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <IconButton onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "16px" }}
                />
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="Individuals Table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Company Reg No.</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((user) => (
                        <TableRow key={user.id} hover>
                          <TableCell scope="row">
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                src={user.image_url}
                                sx={{ width: 56, height: 56 }}
                              ></Avatar>
                              <Typography variant="subtitle1" gutterBottom>
                                {user.full_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{user.phone_number}</TableCell>
                          <TableCell>{user.company_register}</TableCell>
                          <TableCell>
                            <OrganizationDetails
                              getUsers={getOrganizitions}
                              user={user}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
}
