import Swal from "sweetalert2";
import { supabase } from "../../utils/supbase";

const createBasicData = async (tableName, formData, getData, handleClose) => {
  await supabase
    .from(tableName)
    .insert([formData])
    .then((data, error) => {
      if (data) {
        Swal.fire("Done!", "Data has been added successfully.", "success");
        getData();
        handleClose();
      } else if (error) {
        Swal.fire(
          "Error!",
          "An unexpected error occurred, please try again later",
          "error"
        );
        handleClose();
      }
    });
};

export default createBasicData;
