import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import editBasicData from "../../services/basicData/EditBasicData";

const EditSpecialization = ({ getSpecializations, specialization }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              name_ar: formData.get("name_ar"),
              name_en: formData.get("name_en"),
            };
            console.log(formJson);
            editBasicData(
              "specializations",
              {
                name_ar: formData.get("name_ar"),
                name_en: formData.get("name_en"),
              },
              getSpecializations,
              handleClose,
              specialization.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Edit Specialization Info</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="name_ar"
            name="name_ar"
            label="Name Arabic"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={specialization?.name_ar}
          />
          <TextField
            required
            id="name_en"
            name="name_en"
            label="Name English"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={specialization?.name_en}
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditSpecialization;
