import * as React from "react";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { supabase } from "../../utils/supbase";
import { Card, Container, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import LawyerDetails from "./LawyerDetails";
import editBasicData from "../../services/basicData/EditBasicData";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Switch,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";

export default function LawersList() {
  const [lawyers, setLawyers] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    getOffers();
  }, []);

  React.useEffect(() => {
    getLawyers();
  }, []);

  const getLawyers = async () => {
    const { data } = await supabase
      .from("lawyers")
      .select(
        `
    *, 
    cities(id, name_en, name_ar),
    specializations(id, name_en, name_ar),
    academic_qualifications(id, name_en, name_ar),
    work_areas(id, name_en, name_ar),
    banks(id, name_en, name_ar)
    `
      )
      .order("created_at", { ascending: false });
    console.log(data);
    setLawyers(data);
  };

  const getOffers = async () => {
    const { data } = await supabase
      .from("orders_offers")
      .select(`*, offers_statuses(id, name_ar, name_en)`);
    console.log(data);
    setOffers(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActiveStatusChange = (event, lawyer) => {
    console.log(
      `Updating active status for lawyer:  ${lawyer.full_name}: ${event.target.checked}`
    );
    editBasicData(
      "lawyers",
      { approved: event.target.checked },
      () => {},
      () => {},
      lawyer.id
    );
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredLawyers = lawyers?.filter(
    (lawyer) =>
      lawyer.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lawyer.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {lawyers.length ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Lawyers List
              </Typography>
            </Paper>
            <div>
              <div>
                <TextField
                  variant="outlined"
                  label="Search Lawyers"
                  fullWidth
                  size="large"
                  margin="normal"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <IconButton onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "16px" }}
                />
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="Lawyers Table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lawyer</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredLawyers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((lawyer) => (
                        <TableRow key={lawyer.id} hover>
                          <TableCell scope="row">
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                src={lawyer.image_url}
                                sx={{ width: 56, height: 56 }}
                              ></Avatar>
                              <Typography variant="subtitle1" gutterBottom>
                                {lawyer.full_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {/* <input
                            type="text"
                            defaultValue={lawyer.phone_number}
                            onChange={(event) => handleMobileNumberChange(event, lawyer)}
                          /> */}
                            {lawyer.phone_number}
                          </TableCell>
                          <TableCell>
                            <Switch
                              defaultChecked={lawyer.approved}
                              onChange={(event) =>
                                handleActiveStatusChange(event, lawyer)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell>
                            {new Date(lawyer.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <LawyerDetails
                              getLawyers={getLawyers}
                              lawyer={lawyer}
                              offers={offers.filter(
                                (x) => x.lawyer_id == lawyer.id
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={lawyers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
}
