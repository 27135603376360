import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton } from "@mui/material";
import GradingIcon from "@mui/icons-material/Grading";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PaidIcon from "@mui/icons-material/Paid";
import { styled } from "@mui/material/styles";
import editBasicData from "../../services/basicData/EditBasicData";
import {
  Card,
  Link,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import {
  Phone,
  Email,
  LocationOn,
  Cake,
  AssignmentInd,
  Yard,
  Badge,
  DateRange,
  Work,
  School,
  MonetizationOn,
  AccountBalance,
  AccountBalanceWallet,
} from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
}));

const StyledName = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const StyledInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const paperStyle = {
  padding: "1rem",
  boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
  borderRadius: "20px",
};
const paperStyle2 = {
  padding: "1rem",
  boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
  borderRadius: "20px",
  textAlign: "start",
};
const LawyerDetails = ({ getLawyers, lawyer, offers }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <AssignmentIndIcon color="primary" />
      </IconButton>
      <Dialog
        fullScreen
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              phone_number: formData.get("phone_number"),
            };
            console.log(formJson);
            editBasicData(
              "lawyers",
              {
                phone_number: formData.get("phone_number"),
              },
              getLawyers,
              handleClose,
              lawyer.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Lawyer Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeader
                  disableTypography="alse"
                  avatar={
                    <StyledAvatar
                      alt={lawyer.full_name}
                      src={lawyer.image_url}
                    />
                  }
                  title={
                    <StyledName variant="h4">{lawyer.full_name}</StyledName>
                  }
                  subheader={
                    <StyledInfo variant="body1">{lawyer.brief}</StyledInfo>
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Contact Information</b>
                      </Typography>
                      {lawyer.email ? (
                        <Typography variant="body2" gutterBottom>
                          <Email /> {lawyer.email}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.phone_number ? (
                        <Typography variant="body2" gutterBottom>
                          <Phone /> {lawyer.phone_number}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Personal Details</b>
                      </Typography>
                      {lawyer.national_identifier ? (
                        <Typography variant="body2">
                          <Badge /> ID No: {lawyer.national_identifier}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.birth_date ? (
                        <Typography variant="body2">
                          <Cake /> Birthdate: {lawyer.birth_date}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.national_identification ? (
                        <Typography variant="body2" gutterBottom>
                          <Badge /> National ID:{" "}
                          {lawyer.national_identification}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography variant="body2" gutterBottom>
                        <DateRange /> Created At:{" "}
                        {new Date(lawyer.created_at).toLocaleDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Professional Details</b>
                      </Typography>
                      {lawyer.specialization ? (
                        <Typography variant="body2" gutterBottom>
                          <Work /> Specialization:{" "}
                          {lawyer.specialization?.name_en}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.work_areas ? (
                        <Typography variant="body2" gutterBottom>
                          <Work /> Work Area: {lawyer.work_areas?.name_en}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.academic_qualifications ? (
                        <Typography variant="body2" gutterBottom>
                          <School /> Academic Qualification:{" "}
                          {lawyer.academic_qualifications?.name_en}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.experience_years ? (
                        <Typography variant="body2" gutterBottom>
                          <Yard /> Experience: {lawyer.experience_years} years
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.consultation_cost ? (
                        <Typography variant="body2" gutterBottom>
                          <MonetizationOn /> Consultation Cost:{" "}
                          {lawyer.consultation_cost}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Bank Details</b>
                      </Typography>
                      {lawyer.banks ? (
                        <Typography variant="body2" gutterBottom>
                          <AccountBalance /> Bank: {lawyer.banks?.name_en}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.bank_account_number ? (
                        <Typography variant="body2" gutterBottom>
                          <AccountBalanceWallet /> Account Number:{" "}
                          {lawyer.bank_account_number}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {lawyer.iban ? (
                        <Typography variant="body2" gutterBottom>
                          <AccountBalanceWallet /> IBAN: {lawyer.iban}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        <b>Credentials</b>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <Link href={lawyer.academic_degree_url} target="_blank">
                          Academic Degree
                        </Link>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <Link href={lawyer.legal_license_url} target="_blank">
                          Legal License
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid spacing={1} container xs={12} sm={6}>
              <Grid item xs={12} sm={12}>
                <StyledCard>
                  <CardHeader></CardHeader>
                  <CardContent>
                    <Grid container spacing={2} marginTop="2rem">
                      <Grid item xs={12} md={6}>
                        <Paper style={paperStyle2}>
                          <GradingIcon
                            style={{ fontSize: "50px", color: "goldenrod" }}
                          />
                          <Typography variant="h3">
                            {
                              offers.filter((x) => x.offers_statuses.id == 2)
                                .length
                            }
                          </Typography>
                          <Typography variant="body1">
                            Total Accepted Offers
                          </Typography>
                        </Paper>{" "}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper style={paperStyle2}>
                          <PaidIcon
                            style={{ fontSize: "50px", color: "goldenrod" }}
                          />
                          <Typography variant="h3">
                            {offers
                              ?.filter((x) => x.offers_statuses.id == 2)
                              ?.map((x) => x.offered_price)
                              ?.reduce((a, b) => a + b, 0)}{" "}
                          </Typography>
                          <Typography variant="body1">Total Amounts</Typography>
                        </Paper>{" "}
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={12}>
                <StyledCard>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="goldenrod"
                      fontWeight="bold"
                      style={{ marginBottom: "8px" }}
                    >
                      Edit Phone Number
                    </Typography>
                    <TextField
                      required
                      id="phone_number"
                      name="phone_number"
                      label="Phone Number"
                      fullWidth
                      variant="standard"
                      margin="dense"
                      defaultValue={lawyer?.phone_number}
                    />
                    <Button type="submit" variant="contained" color="success">
                      Submit
                    </Button>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LawyerDetails;
