import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import createBasicData from "../../services/basicData/CreateBasicData";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";





const NewService = ({ getServices, categories }) => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

 

  return (
    <>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Add New Service
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              name_ar: formData.get("name_ar"),
              name_en: formData.get("name_en"),
              category: formData.get("category"),
              order: formData.get("order"),
            };
            console.log(formJson);
            createBasicData("services", formJson, getServices, handleClose);
          },
        }}
      >
        <DialogTitle fontWeight="bold">Add New Service</DialogTitle>
        <DialogContent>
        <br></br>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              label="Category"
              value={category}
              onChange={handleChange}
            >
              {categories.map((element, index) => (
                <MenuItem key={index} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            id="name_ar"
            name="name_ar"
            label="Name Arabic"
            fullWidth
            variant="standard"
            margin="dense"
          />
          <TextField
            required
            id="name_en"
            name="name_en"
            label="Name English"
            fullWidth
            variant="standard"
            margin="dense"
          />
          <TextField
            required
            id="order"
            name="order"
            label="Order"
            fullWidth
            variant="standard"
            margin="dense"
          />
          
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewService;
