import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import NewDuration from "./NewDuration";

const DurationsList = () => {
  const [durations, setDurations] = useState();

  useEffect(() => {
    getDurations();
  }, []);

  const getDurations = async () => {
    const { data } = await supabase.from("consultations_durations").select("*");
    setDurations(data);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {durations ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Session Durations List
              </Typography>
              <NewDuration getDurations={getDurations} />
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {durations.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={<></>}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AlarmOnIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={`${element.duration} - Hour`} />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default DurationsList;
