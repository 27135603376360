import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import deleteBasicData from "../../services/basicData/deleteBasicData";
import EditOfferStatus from "./EditOfferStatus";
import AutoModeIcon from "@mui/icons-material/AutoMode";

const OfferStatusList = () => {
  const [offerStatuses, setOfferStatuses] = useState();

  useEffect(() => {
    getOffersStatuses();
  }, []);

  const getOffersStatuses = async () => {
    const { data } = await supabase.from("offers_statuses").select("*");
    setOfferStatuses(data);
  };

  const deleteOfferStatus = async (id) => {
    deleteBasicData("offers_statuses", getOffersStatuses, id);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {offerStatuses ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Offers Statuses List
              </Typography>
              {/* <NewOfferStatus getOfferStatuses={getOffersStatuses} /> */}
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {offerStatuses.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={
                        <>
                          {/* <EditIcon color="primary" onClick /> */}
                          <EditOfferStatus
                            getOffersStatuses={getOffersStatuses}
                            offerStatus={element}
                          />
                          {/* <IconButton
                            onClick={() => deleteOfferStatus(element.id)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton> */}
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AutoModeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={element.name_ar}
                        secondary={element.name_en}
                      />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default OfferStatusList;
