import * as React from "react";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { supabase } from "../../utils/supbase";
import { Card, Container, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import editBasicData from "../../services/basicData/EditBasicData";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import OrderDetails from "./OrderDetails";
import UsersDetails from "../users/UserDetails.jsx";
import OrganizitionDetails from "../Organizations/OrganizitionDetails.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import deleteBasicData from "../../services/basicData/deleteBasicData";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Switch,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";

export default function LawersList() {
  const [orders, setOrders] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    getOrders();
  }, []);

  React.useEffect(() => {
    getOffers();
  }, []);

  const getOrders = async () => {
    const { data } = await supabase
      .from("orders")
      .select(
        `
    *, 
    services(id, name_en, name_ar, category),
    individuals(*),
    organizations(*),
    orders_statuses(id, name_en, name_ar)
    `
      )
      .order("created_at", { ascending: false });
    console.log(data);
    setOrders(data);
  };

  const getOffers = async () => {
    const { data } = await supabase.from("orders_offers").select(`*, 
    offers_statuses(id, name_ar, name_en),
    lawyers(*)`);
    console.log(data);
    setOffers(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredOrders = orders?.filter(
    (order) =>
      order.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.overview?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.services?.name_ar
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      order.individuals?.full_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      order.organizations?.full_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      order.orders_statuses?.name_ar
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const deleteOrder = async (id) => {
    deleteBasicData("orders", getOrders, id);
  };

  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {orders.length ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Orders List
              </Typography>
            </Paper>
            <div>
              <div>
                <TextField
                  variant="outlined"
                  label="Search Orders"
                  fullWidth
                  size="large"
                  margin="normal"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <IconButton onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "16px" }}
                />
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="Orders Table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order #</TableCell>
                      <TableCell>Overview</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Client</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOrders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order) => (
                        <TableRow key={order.id} hover>
                          <TableCell scope="row">{order.id}</TableCell>
                          <TableCell
                            style={{
                              maxWidth: "250px",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {order.overview}
                          </TableCell>
                          <TableCell>{order.services?.name_ar}</TableCell>
                          <TableCell>{order.services?.category}</TableCell>
                          <TableCell>
                            {order.orders_statuses?.name_ar}
                          </TableCell>
                          <TableCell>
                            {order.organizations ? (
                              <OrganizitionDetails
                                getUsers={() => {}}
                                user={order.organizations}
                              />
                            ) : (
                              <UsersDetails
                                getUsers={() => {}}
                                user={order.individuals}
                              />
                            )}
                            {order.organizations
                              ? order.organizations?.full_name
                              : order.individuals?.full_name}
                          </TableCell>
                          <TableCell>
                            {new Date(order.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <OrderDetails
                              getOrders={getOrders}
                              order={order}
                              offers={offers.filter(
                                (x) => x.order_id == order.id
                              )}
                            />
                            <IconButton onClick={() => deleteOrder(order.id)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
}
