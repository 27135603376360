import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditAboutUs from "./EditAboutUs";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState();

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = async () => {
    const { data } = await supabase.from("about_us").select("*");
    setAboutUs(data[0]);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {aboutUs ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Adala Founder Information
              </Typography>
              <EditAboutUs aboutUs={aboutUs} getAboutUs={getAboutUs} />
            </Paper>
            <Card
              style={{
                marginTop: "2rem",
                padding: "1rem",
                boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
                borderRadius: "20px",
              }}
            >
              <Avatar
                style={{ margin: "auto", width: "80px", height: "80px" }}
                alt="Abdullah"
                src={aboutUs?.founder_image_url}
              />
              <Typography variant="h4">
                {aboutUs?.founder_name_en} - {aboutUs?.founder_name_ar}
              </Typography>
              <Typography variant="body1">
                {aboutUs?.founder_description_en} -{" "}
                {aboutUs?.founder_description_ar}{" "}
              </Typography>
              <Grid
                container
                spacing={2}
                marginY="1rem"
                display="flex"
                justifyContent="space-around"
              >
                <Grid
                  item
                  xs={5}
                  style={{
                    padding: "1rem",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
                  }}
                >
                  <Typography variant="body1">{aboutUs?.content_en}</Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    padding: "1rem",
                    boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1">{aboutUs?.content_ar}</Typography>
                </Grid>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default AboutUs;
