import "./App.css";
import CitiesList from "./screens/cities/CitiesList";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginScreen from "./screens/auth/Login";
import BanksList from "./screens/banks/BanksList";
import OfferStatusList from "./screens/offers_statuses/OfferStatusList";
import AcademicInfoList from "./screens/academic/AcademicInfoList";
import SpecializationsList from "./screens/specializations/SpecializationsList";
import WorkAreasList from "./screens/work_areas/WorkAreasList";
import PrivacyPoliciesList from "./screens/privacy_policy/PrivacyPolicyList";
import Dashboard from "./screens/dashboard/Dashboard";
import TermsList from "./screens/terms/TermsList";
import ClientPrivacyPoliciesList from "./screens/privacy_policy_clients/PrivacyPolicyList";
import ClientTermsList from "./screens/terms_clients/TermsList";
import DurationsList from "./screens/durations/DurationsList";
import AboutUs from "./screens/about_us/AboutUs";
import LawersList from "./screens/lawyers/LawersList";
import UsersList from "./screens/users/UsersList";
import OrganizitionsList from "./screens/Organizations/OrganizitionsList";
import OrdersList from "./screens/orders/OrdersList";
import ServicesList from "./screens/services/ServicesList";

function App() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="App">
      <Routes>
        {/* <Route path="*" re element={<Navigate replace to={"/"} />} /> */}
        <Route
          path="/"
          element={!user ? <LoginScreen /> : <Navigate to={"/supervisor"} />}
        />
        {/*Admin*/}
        <Route path="/supervisor">
          <Route index element={user ? <Dashboard /> : <Navigate to={"/"} />} />
          <Route
            path="cities"
            element={user ? <CitiesList /> : <Navigate to={"/"} />}
          />
          <Route
            path="banks"
            element={user ? <BanksList /> : <Navigate to={"/"} />}
          />
          <Route
            path="offers_statuses"
            element={user ? <OfferStatusList /> : <Navigate to={"/"} />}
          />
          <Route
            path="academic_qualifications"
            element={user ? <AcademicInfoList /> : <Navigate to={"/"} />}
          />
          <Route
            path="specializations"
            element={user ? <SpecializationsList /> : <Navigate to={"/"} />}
          />
          <Route
            path="work_areas"
            element={user ? <WorkAreasList /> : <Navigate to={"/"} />}
          />
          <Route
            path="privacy_policy"
            element={user ? <PrivacyPoliciesList /> : <Navigate to={"/"} />}
          />
          <Route
            path="terms"
            element={user ? <TermsList /> : <Navigate to={"/"} />}
          />
          <Route
            path="privacy_policy_client"
            element={
              user ? <ClientPrivacyPoliciesList /> : <Navigate to={"/"} />
            }
          />
          <Route
            path="terms_client"
            element={user ? <ClientTermsList /> : <Navigate to={"/"} />}
          />
          <Route
            path="durations"
            element={user ? <DurationsList /> : <Navigate to={"/"} />}
          />
          <Route
            path="about"
            element={user ? <AboutUs /> : <Navigate to={"/"} />}
          />
          <Route
            path="lawyers"
            element={user ? <LawersList /> : <Navigate to={"/"} />}
          />
          <Route
            path="individuals"
            element={user ? <UsersList /> : <Navigate to={"/"} />}
          />
          <Route
            path="organizations"
            element={user ? <OrganizitionsList /> : <Navigate to={"/"} />}
          />
          <Route
            path="orders"
            element={user ? <OrdersList /> : <Navigate to={"/"} />}
          />
          <Route
            path="services"
            element={user ? <ServicesList /> : <Navigate to={"/"} />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
