import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DeleteIcon from "@mui/icons-material/Delete";
import NewCity from "./NewCity";
import EditCity from "./EditCity";
import deleteBasicData from "../../services/basicData/deleteBasicData";

const CitiesList = () => {
  const [cities, setCities] = useState();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    const { data } = await supabase.from("cities").select("*");
    setCities(data);
  };

  const deleteCity = async (id) => {
    deleteBasicData("cities", getCities, id);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {cities ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Cities List
              </Typography>
              <NewCity getCities={getCities} />
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {cities.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={
                        <>
                          {/* <EditIcon color="primary" onClick /> */}
                          <EditCity getCities={getCities} city={element} />
                          <IconButton onClick={() => deleteCity(element.id)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <LocationCityIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={element.name_ar}
                        secondary={element.name_en}
                      />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default CitiesList;
