import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import editBasicData from "../../services/basicData/EditBasicData";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const EditService = ({ getServices, service, categories }) => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              name_ar: formData.get("name_ar"),
              name_en: formData.get("name_en"),
              category: formData.get("category"),
              order: formData.get("order"),
            };
            console.log(formJson);
            editBasicData(
              "services",
              {
                name_ar: formData.get("name_ar"),
                name_en: formData.get("name_en"),
                category: formData.get("category"),
                order: formData.get("order"),
              },
              getServices,
              handleClose,
              service.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Edit Service Info</DialogTitle>
        <DialogContent>
          <br></br>
          {/* <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              label="Category"
              defaultValue={service?.category}
              onChange={handleChange}
              inputProps={{ readOnly: true }}
            >
              {categories.map((element, index) => (
                <MenuItem key={index} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <TextField
            disabled
            id="category"
            name="category"
            label="Category"
            fullWidth
            variant="filled"
            margin="dense"
            defaultValue={service?.category}
          />
          <TextField
            required
            id="name_ar"
            name="name_ar"
            label="Name Arabic"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={service?.name_ar}
          />
          <TextField
            required
            id="name_en"
            name="name_en"
            label="Name English"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={service?.name_en}
          />
          <TextField
            required
            id="order"
            name="order"
            label="Order"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={service?.order}
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditService;
