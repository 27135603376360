// components/CustomBarChart.js
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Spinner from "./Spinner";

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CustomBarChart = ({ label, GetData }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: label,
        data: [],
        backgroundColor: "#daa5205e",
        borderColor: "#daa520",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const getData = async () => {
      const userCounts = await GetData();
      const labels = userCounts.map((item) => item?.month);
      const data = userCounts.map((item) => item?.count);

      setChartData((prevChartData) => ({
        ...prevChartData,
        labels,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data,
          },
        ],
      }));
    };

    getData();
  }, [GetData]);

  return (
    <div>
      {chartData.labels.length > 0 && chartData.datasets[0].data.length > 0 ? (
        <Bar data={chartData} />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CustomBarChart;
