import Swal from "sweetalert2";
import { supabase } from "../../utils/supbase";

const editBasicData = async (tableName, formData, getData, handleClose, id) => {
  await supabase
    .from(tableName)
    .update(formData)
    .eq("id", id)
    .then((data, error) => {
      if (data) {
        Swal.fire("Done!", "Data has been updated successfully.", "success");
        getData();
        handleClose();
      } else if (error) {
        Swal.fire(
          "Error!",
          "An unexpected error occurred, please try again later",
          "error"
        );
        handleClose();
      }
    });
};

export default editBasicData;
