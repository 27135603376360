import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import editBasicData from "../../services/basicData/EditBasicData";

const EditTerms = ({ getTerms, terms }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              title_ar: formData.get("title_ar"),
              title_en: formData.get("title_en"),
              content_ar: formData.get("content_ar"),
              content_en: formData.get("content_en"),
            };
            editBasicData(
              "qistas_terms_and_conditions",
              formJson,
              getTerms,
              handleClose,
              terms.id
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Edit Terms Section</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="title_ar"
            name="title_ar"
            label="Title Arabic"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={terms?.title_ar}
          />
          <TextField
            required
            id="title_en"
            name="title_en"
            label="Title English"
            fullWidth
            variant="standard"
            margin="dense"
            defaultValue={terms?.title_en}
          />
          <TextField
            required
            id="content_ar"
            name="content_ar"
            label="Description Arabic"
            fullWidth
            multiline
            maxRows={8}
            dir="rtl"
            variant="standard"
            margin="dense"
            defaultValue={terms?.content_ar}
          />
          <TextField
            required
            id="content_en"
            name="content_en"
            label="Description English"
            fullWidth
            multiline
            maxRows={8}
            variant="standard"
            margin="dense"
            defaultValue={terms?.content_en}
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTerms;
