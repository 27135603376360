import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DeleteIcon from "@mui/icons-material/Delete";
import NewService from "./NewService";
import EditService from "./EditService";
import deleteBasicData from "../../services/basicData/deleteBasicData";
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';

const ServicesList = () => {
  const [services, setServices] = useState();
  const [categories, setCategories] = React.useState([]);

  useEffect(() => {
      getServices();
    // getCategories();
    }, []);
    
    const getServices = async () => {
        const { data } = await supabase.from("services")
                                        .select("*")
                                        .order("category", { ascending: true })
                                        .order("order", { ascending: true });
        setServices(data);
        
        var cat = data.map(x=>x.category).filter(
            (value, index, current_value) => current_value.indexOf(value) === index);
            setCategories(cat);
    };
    
    // const getCategories = async () => {        
    //     const { data } = await supabase.from("services")
    //                                     .select("category, order");
    //     console.log(data);
    //     var cat = data.reduce((acc, curr) => {
    //         if (!acc[curr.category] || curr.order > acc[curr.category].order) {
    //           acc[curr.category] = { category: curr.category, order: curr.order };
    //         }
    //         return acc;
    //       }, {});
    //       Object.values(cat).map(({ category, order }) => ({ category, order }));
    // };

  const deleteService = async (id) => {
    deleteBasicData("services", getServices, id);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {services ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Services List
              </Typography>
              <NewService getServices={getServices} categories={categories}/>
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {services.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={
                        <>
                          {/* <EditIcon color="primary" onClick /> */}
                          <EditService getServices={getServices} service={element} categories={categories} />
                          <IconButton onClick={() => deleteService(element.id)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <KeyboardCommandKeyIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary= 
                        {
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body1"
                                color="text.primary"
                              >
                                {element.name_en}  
                              </Typography>
                                  {" - " + element.name_ar}
                            </React.Fragment>
                          }
                        secondary={element.category}
                          
                      />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default ServicesList;
