import { Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import CustomBarChart from "../../components/BarChart";
import {
  GetLawyersChart,
  GetIndividualsChart,
} from "../../services/basicData/ChartsData";
import CustomPieChart from "../../components/PieChart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GradingIcon from "@mui/icons-material/Grading";
import BadgeIcon from "@mui/icons-material/Badge";
import { supabase } from "../../utils/supbase";
import Spinner from "../../components/Spinner";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";

const Dashboard = () => {
  const [individuals, setIndividuals] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [Orders, setOrders] = useState([]);
  const [offers, setOffers] = useState([]);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    GetIndividuals();
    GetLawyers();
    GetOrganizitions();
    GetOrders();
    GetSessions();
    GetOffers();
  }, []);

  const paperStyle = {
    padding: "1rem",
    boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
    borderRadius: "20px",
  };
  const paperStyle2 = {
    padding: "1rem",
    boxShadow: "0px 0px 26px -11px rgba(194,194,194,1)",
    borderRadius: "20px",
    textAlign: "start",
  };
  const GetIndividuals = async () => {
    const { data } = await supabase.from("individuals").select(
      `id
    `
    );
    setIndividuals(data);
  };
  const GetLawyers = async () => {
    const { data } = await supabase.from("lawyers").select(
      `id
    `
    );
    setLawyers(data);
  };
  const GetOrganizitions = async () => {
    const { data } = await supabase.from("organizations").select(
      `id
    `
    );
    setOrganizations(data);
  };
  const GetOrders = async () => {
    const { data } = await supabase.from("orders").select(
      `id
    `
    );
    setOrders(data);
  };
  const GetOffers = async () => {
    const { data } = await supabase
      .from("orders_offers")
      .select(
        `id
    `
      )
      .eq("offer_status_id", "2");
    setOffers(data);
  };
  const GetSessions = async () => {
    const { data } = await supabase.from("sessions").select(
      `id
    `
    );
    setSessions(data);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {lawyers.length &&
        individuals.length &&
        organizations.length &&
        Orders.length &&
        offers.length &&
        sessions.length ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Welcome To Statstics Dashboard
              </Typography>
            </Paper>
            <Grid container spacing={2} marginTop="2rem">
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <GradingIcon
                    style={{ fontSize: "50px", color: "goldenrod" }}
                  />
                  <Typography variant="h3">{individuals?.length}</Typography>
                  <Typography variant="body1">Total Users</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <SupportAgentIcon
                    style={{ fontSize: "50px", color: "goldenrod" }}
                  />
                  <Typography variant="h3">{lawyers?.length}</Typography>
                  <Typography variant="body1">Total Lawyers</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <BadgeIcon style={{ fontSize: "50px", color: "goldenrod" }} />
                  <Typography variant="h3">{organizations?.length}</Typography>
                  <Typography variant="body1">Total Organizations</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <ViewModuleIcon
                    style={{ fontSize: "50px", color: "goldenrod" }}
                  />
                  <Typography variant="h3">{Orders?.length}</Typography>
                  <Typography variant="body1">Total Orders</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <ChecklistRtlIcon
                    style={{ fontSize: "50px", color: "goldenrod" }}
                  />
                  <Typography variant="h3">{offers?.length}</Typography>
                  <Typography variant="body1">Accepted Offers</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Paper style={paperStyle2}>
                  <PermPhoneMsgIcon
                    style={{ fontSize: "50px", color: "goldenrod" }}
                  />
                  <Typography variant="h3">{sessions?.length}</Typography>
                  <Typography variant="body1">Completed Sessions</Typography>
                </Paper>{" "}
              </Grid>
              <Grid item xs={6} md={8}>
                <Paper style={paperStyle}>
                  {" "}
                  <CustomBarChart
                    label="Lawyers Count"
                    GetData={GetLawyersChart}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} md={4}>
                <Paper style={paperStyle}>
                  <CustomPieChart
                    label="Clients Data by Date"
                    GetData={GetIndividualsChart}
                  />
                </Paper>{" "}
              </Grid>
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default Dashboard;
