import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import createBasicData from "../../services/basicData/CreateBasicData";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const durationOptions = [
  { duration: "04:00:00", label: "4 Hour" },
  { duration: "05:00:00", label: "5 Hour" },
  { duration: "06:00:00", label: "6 Hour" },
  { duration: "07:00:00", label: "7 Hour" },
  { duration: "08:00:00", label: "8 Hour" },
];
const NewDuration = ({ getDurations }) => {
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Add New Duration
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = {
              duration: formData.get("duration"),
            };
            console.log(formJson);
            createBasicData(
              "consultations_durations",
              formJson,
              getDurations,
              handleClose
            );
          },
        }}
      >
        <DialogTitle fontWeight="bold">Add New Duration</DialogTitle>
        <DialogContent>
          {/* <TextField
            required
            id="duration"
            name="duration"
            label="Duration"
            fullWidth
            variant="standard"
            margin="dense"
          /> */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
            <Select
              labelId="duration-label"
              id="duration"
              name="duration"
              label="Duration"
              value={duration}
              onChange={handleChange}
            >
              {durationOptions.map((element, index) => (
                <MenuItem key={index} value={element.duration}>
                  {element.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewDuration;
