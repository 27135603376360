import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supbase";
import Sidebar from "../../components/Sidebar";
import { Card, Container, Paper } from "@mui/material";
import Spinner from "../../components/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GavelIcon from "@mui/icons-material/Gavel";
import EditTerms from "./EditTerms";

const TermsList = () => {
  const [terms, setTerms] = useState();

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    const { data } = await supabase
      .from("l2c_terms_and_conditions")
      .select("*")
      .order("order", { ascending: true });
    setTerms(data);
  };
  return (
    <>
      <Sidebar />
      <Container style={{ maxWidth: "1400px" }}>
        {terms ? (
          <>
            <Paper
              style={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "none",
                marginTop: "2rem",
              }}
            >
              <Typography
                textAlign="start"
                variant="h4"
                color="goldenrod"
                fontWeight="bold"
              >
                Lawyers Terms & Conditions
              </Typography>
              {/* <NewWorkArea getWorkAreas={getWorkAreas} /> */}
            </Paper>
            <Card style={{ marginTop: "2rem", padding: "1rem" }}>
              <Grid item xs={12} md={6}>
                <List>
                  {terms.map((element) => (
                    <ListItem
                      divider={true}
                      key={element.id}
                      secondaryAction={
                        <>
                          <EditTerms getTerms={getTerms} terms={element} />
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <GavelIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={element.title_ar}
                        secondary={element.title_en}
                      />
                    </ListItem>
                  ))}
                  ;
                </List>
              </Grid>
            </Card>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

export default TermsList;
