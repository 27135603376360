import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/services/authApi";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import SchoolIcon from "@mui/icons-material/School";
import StarsIcon from "@mui/icons-material/Stars";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BadgeIcon from "@mui/icons-material/Badge";
import GavelIcon from "@mui/icons-material/Gavel";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleIcon from "@mui/icons-material/People";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';

const drawerWidth = 240;

const sidebarData = [
  {
    title: "Dashboard",
    icon: <DashboardIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor",
  },
  {
    title: "Lawyers",
    icon: <AssignmentIndIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/lawyers",
  },
  {
    title: "Orders",
    icon: <AssignmentIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/orders",
  },
  {
    title: "Individuals",
    icon: <PeopleIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/individuals",
  },
  {
    title: "Organizations",
    icon: <CorporateFareIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/organizations",
  },
  {
    title: "Services",
    icon: <KeyboardCommandKeyIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/services",
  },
  {
    title: "Cities",
    icon: <LocationCityIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/cities",
  },
  {
    title: "Banks",
    icon: <AccountBalanceIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/banks",
  },
  {
    title: "Offers Statuses",
    icon: <AutoModeIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/offers_statuses",
  },
  {
    title: "Academic Qualifications",
    icon: <SchoolIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/academic_qualifications",
  },

  {
    title: "Privacy Policy (Lawyers)",
    icon: <AdminPanelSettingsIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/privacy_policy",
  },
  {
    title: "Terms (Lawyers)",
    icon: <GavelIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/terms",
  },
  {
    title: "Specializations",
    icon: <StarsIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/specializations",
  },
  {
    title: "Work Areas",
    icon: <BadgeIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/work_areas",
  },
  {
    title: "Privacy Policy (Clients)",
    icon: <AdminPanelSettingsIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/privacy_policy_client",
  },
  {
    title: "Terms (Clients)",
    icon: <GavelIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/terms_client",
  },
  {
    title: "Session Durations",
    icon: <AlarmOnIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/durations",
  },
  {
    title: "Founder Info",
    icon: <AssignmentIndIcon style={{ color: "goldenrod" }} />,
    link: "/supervisor/about",
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", direction: "ltr" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ background: "goldenrod", borderRadius: "0 0 30px 30px" }}
        open={open}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Toolbar style={{ padding: "0" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="div"
              fontWeight="bold"
              color="white"
            >
              ADALA APP
            </Typography>
          </Toolbar>
          <Typography
            variant="body1"
            display="flex"
            justifyItems="center"
            noWrap
            component="div"
            fontWeight="bold"
            color="white"
          >
            <img
              src={user.profileImg.url}
              width="28px"
              style={{ borderRadius: "50%", marginRight: "6px" }}
              alt=""
            />{" "}
            Welcome {user.userName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sidebarData.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <Link to={item.link}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    color="goldenrod"
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  ></ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => dispatch(logoutUser())}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={"Logout"}
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "#d32f2f", fontWeight: "700" }}
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
